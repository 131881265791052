<template>
    <modal name="work-allocation" classes="work-modal" :adaptive="true" height="80%" width="70%" @before-open="beforeOpen" @before-close="onBeforeClose">
        <div class="flex flex-col max-h-full h-full">
            <div class="bg-primary py-2 px-4 flex items-center justify-between">
                <h3 class="text-white-text font-semibold text-lg flex items-center gap-2">Case Allocation</h3>
                <span class="cursor-pointer text-white-text cross" @click="hideModal">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" fill="white" class="h-7 w-7">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                </span>
            </div>
            <div class="overflow-hidden max-h-full border-solid py-5 px-6 border-0 border-t border-gray-300 scroll-bar flex-grow flex gap-4 flex-wrap">
                <div class="relative border border-solid border-gray-100 rounded-lg shadow-lg flex-1 flex flex-col max-h-full overflow-hidden">
                    <div class="text-base tracking-wide bg-blue-100 py-1 px-2 text-blue-600">Teams</div>
                    <div class="relative w-full p-2">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5" placeholder="Search" v-model="search.teams" />
                    </div>
                    <div class="px-2 flex overflow-auto scroll-bar">
                        <table class="w-full text-sm text-left text-gray-500 p-2">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-4 py-2 bg-gray-50">Action</th>
                                    <!-- <th scope="col" class="px-4 py-2 bg-gray-50">Remove</th> -->
                                    <th scope="col" class="px-4 py-2 bg-gray-50">Teams</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="loadingStates.teams">
                                    <tr>
                                        <td colspan="3" class="px-4 py-8 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                </template>
                                <template v-else-if="filteredTeams.length > 0">
                                    <tr class="bg-white border-b" v-for="(team, ind_team) in filteredTeams" :key="team.id">
                                        <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" @change="onChange($event, ind_team, 'teamsStates')" :checked="team.state == 'assigned' || team.status === 'add'" :indeterminate.prop="team.state === 'intermediate'" />
                                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <!-- <td class="px-4 py-2">
                                            <div class="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" @change="onChange(ind_team, 'remove', 'teamsStates')" :checked="team.status == 'remove'" />
                                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                            </div>
                                        </td> -->
                                        <td class="px-4 py-2">{{ team.name }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="px-4 py-2 text-center">NO TEAM FOUND!</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="mr-4 mt-auto ml-auto pt-2">
                        <button class="save-button inline-flex items-center px-4 py-2 my-2 mr-4 border border-solid border-primary hover:bg-primary-focus hover:text-white-text text-primary text-sm font-medium rounded-md" @click="onReset('teams')">Reset</button>
                        <button class="save-button inline-flex items-center px-4 py-2 my-2 bg-primary hover:bg-primary-focus text-white-text text-sm font-medium rounded-md" @click="onSave('team')" :disabled="!isTeamSaveEnabled">Apply</button>
                    </div>
                </div>
                <div class="relative border border-solid border-gray-100 rounded-lg shadow-lg flex-1 flex flex-col max-h-full overflow-hidden">
                    <div class="text-base tracking-wide bg-blue-100 py-1 px-2 text-blue-600">Users</div>
                    <div class="relative w-full p-2">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5" placeholder="Search" v-model="search.users" />
                    </div>
                    <div class="px-2 flex overflow-auto scroll-bar">
                        <table class="w-full text-sm text-left text-gray-500 p-2">
                            <thead class="text-xs text-gray-700 uppercase">
                                <tr>
                                    <th scope="col" class="px-4 py-2 bg-gray-50">Action</th>
                                    <!-- <th scope="col" class="px-4 py-2 bg-gray-50">Remove</th> -->
                                    <th scope="col" class="px-4 py-2 bg-gray-50">Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="loadingStates.users">
                                    <tr>
                                        <td colspan="3" class="px-4 py-8 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                </template>
                                <template v-else-if="filteredUsers.length > 0">
                                    <tr class="bg-white border-b" v-for="(user, ind_user) in filteredUsers" :key="user.id">
                                        <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" @change="onChange($event, ind_user, 'usersStates')" :checked="user.state == 'assigned' || user.status === 'add'" :indeterminate.prop="user.state === 'intermediate'" />
                                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <!-- <td class="px-4 py-2">
                                            <div class="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" @change="onChange(ind_user, 'remove', 'usersStates')" :checked="user.status == 'remove'" />
                                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                            </div>
                                        </td> -->
                                        <td class="px-4 py-2">{{ user.user_name }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="px-4 py-2 text-center">NO USER FOUND!</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="mr-4 mt-auto ml-auto pt-2">
                        <button class="save-button inline-flex items-center px-4 py-2 my-2 mr-4 border border-solid border-primary hover:bg-primary-focus hover:text-white-text text-primary text-sm font-medium rounded-md" @click="onReset('users')">Reset</button>
                        <button class="save-button inline-flex items-center px-4 py-2 my-2 bg-primary hover:bg-primary-focus text-white-text text-sm font-medium rounded-md op" @click="onSave('user')" :disabled="!isUserSaveEnabled">Apply</button>
                    </div>
                </div>
            </div>
            <div class="mb-4 px-6"><span class="font-bold">Case(s) Selected:</span> {{ casesCount }}</div>
        </div>
    </modal>
</template>

<script>
import { cloneDeep } from "lodash";
const Loader = () => import("@shared/loader");

export default {
    name: "work-allocation",
    data() {
        return {
            selectedCases: [],
            search: {
                teams: "",
                users: "",
            },
        };
    },
    components: {
        Loader,
    },
    props: {
        loadingStates: {
            type: Object,
            default: () => {},
        },
        allTeams: {
            type: Array,
            default: () => [],
        },
        allUsers: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        hideModal() {
            this.$modal.hide("work-allocation");
        },
        beforeOpen({ params }) {
            this.search = {
                teams: "",
                users: "",
            };
            this.selectedCases = cloneDeep(params.rows);
        },
        onChange({ target }, index, type) {
            let value = target.checked ? "add" : "remove";
            this.$emit("onWorkAllocateChange", { index,value, type });
        },
        onSave(type) {
            this.$emit(`on-save`, { cases: this.selectedCases, type });
        },
        onBeforeClose() {
            this.$emit("getCandidateViews");
        },
        onReset(type) {
            this.$emit(`on-reset`, { type, rows: this.selectedCases });
        },
    },
    computed: {
        casesCount() {
            return this.selectedCases?.length;
        },
        filteredTeams() {
            return this.allTeams.filter((team) => team.name.toLowerCase().includes(this.search.teams.toLowerCase()));
        },
        filteredUsers() {
            return this.allUsers.filter((team) => team.user_name.toLowerCase().includes(this.search.users.toLowerCase()));
        },
        isUserSaveEnabled() {
            return this.allUsers.some((user) => user.status === "add" || user.status === "remove");
        },
        isTeamSaveEnabled() {
            return this.allTeams.some((user) => user.status === "add" || user.status === "remove");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .work-modal {
    border-radius: 20px !important;
}
thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}
.save-button:disabled {
    pointer-events: none;
    opacity: 0.25;
}
</style>
